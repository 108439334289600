import React, { useState, useEffect } from "react";
import ReactSwitch from "react-switch";
import axios from "axios";

const Gateway = ({timeout}) => {
  const [RazorPayout, setRazorpayout] = useState(true);
  const [RazorDeposit, setRazorDeposit] = useState(true);
  const [RazorpayAuto, setRazorpayAuto] = useState(false);
  const [RazorPayKey, setRazorpayKey] = useState();
  const [RazorPaySecretKey, setRazorpaysecretKey] = useState();
  const [AccountName, setAccountName] = useState();
  const [PhoneStatus, setPhoneStatus] = useState(true);
  const [PhoneMID, setPhoneMID] = useState(true);
  const [PhonePeKey, setPhonePeKey] = useState(false);
  const [UpiGateway, setUpiGateway] = useState(false);
  const [UpiGatewayKey, setUpiGatewayKey] = useState("");
  const [dummyTimeout, setDummyTimeout] = useState(timeout);
  const [settingId, setSettingId] = useState("");
  const [mannualUpiId, setMannualUpiId] = useState("");
  const [mannualBankName, setMannualBankName] = useState("");
  const [mannualIFSC, setMannualIFSC] = useState("");
  const [mannualAccount, setMannualAccount] = useState("");
  const [mannualQR, setMannualQR] = useState("");
  const [RazorDeposit2, setRazorDeposit2] = useState(true);
  const [RazorPayout2, setRazorpayout2] = useState(true);
   const [ludoApiStatus, setludoApiStatus] = useState(true);
  const [gakStatus, setgakStatus] = useState(false);
  const [RazorPayKey2, setRazorpayKey2] = useState();
  const [RazorPaySecretKey2, setRazorpaysecretKey2] = useState();
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }
 const [qrCode, setQrCode] = useState('');
  const [secret, setSecret] = useState('');

  
  useEffect(() => {
    const data = axios.get(baseUrl + "gatewaysettings/data", {}).then((res) => {
      console.log(res.data);
      setSettingId(res.data._id ? res.data._id : "");
      setRazorpayout(res.data.RazorPayout);
      setRazorDeposit(res.data.RazorDeposit);
      setRazorpayAuto(res.data.RazorpayAuto);
      setPhoneStatus(res.data.PhonePeKey);
      setPhoneMID(res.data.PhoneMID);
      setPhonePeKey(res.data.PhonePeKey);
        setQrCode(res.data.gakqr);
      setSecret(res.data.gak);
      setgakStatus(res.data.gakStatus)
      setRazorpayKey(res.data.RazorPayKey);
      setRazorpaysecretKey(res.data.RazorPaySecretKey);
      setAccountName(res.data.AccountName);
      setUpiGateway(res?.data?.UpiGateway || false);
      setUpiGatewayKey(res.data.UpiGatewayKey);
      setMannualUpiId(res.data.isManualUPIid);
        setMannualBankName(res.data.isManualBankName)
      setMannualIFSC(res.data.isManualIFSC)
      setMannualAccount(res.data.isManualAccountNumber)
      setMannualQR(res.data.isManualUPIQR)
       setRazorpayout2(res.data.RazorPayout2);
       setludoApiStatus(res.data.ludoApiStatus);
      setRazorDeposit2(res.data.RazorDeposit2);
      setRazorpayKey2(res.data.RazorPayKey2);
      setRazorpaysecretKey2(res.data.RazorPaySecretKey2);
    });
  }, []);
  const handleDummy = async (e) => {
    e.preventDefault();

    const response = await axios.post(baseUrl + `update-timeout`, {
      timeout:dummyTimeout
    });
    
    if (response.data.status === "success") {
      alert("Timeout updated successfully");
    } else {
      alert("Timeout not updated");
    }
  };
  const handleSubmit1 = async (e) => {
    e.preventDefault();

 
// Create a FormData object
const formData = new FormData();

// Append form fields to FormData
formData.append('settingId', settingId);
formData.append('RazorPayout', RazorPayout);
formData.append('RazorDeposit', RazorDeposit);
formData.append('RazorpayAuto', RazorpayAuto);
formData.append('PhoneStatus', PhoneStatus);
formData.append('PhoneMID', PhoneMID);
formData.append('PhonePeKey', PhonePeKey);
formData.append('gak', secret);
formData.append('gakqr', qrCode);
formData.append('RazorPayKey', RazorPayKey);
formData.append('RazorPaySecretKey', RazorPaySecretKey);
formData.append('AccountName', AccountName);
formData.append('UpiGateway', UpiGateway);
formData.append('gakStatus', gakStatus);
formData.append('UpiGatewayKey', UpiGatewayKey);
formData.append('mannualUpiId', mannualUpiId);
formData.append('mannualBankName', mannualBankName);
formData.append('mannualIFSC', mannualIFSC);
formData.append('mannualAccount', mannualAccount);
formData.append('mannualQR', mannualQR);
formData.append('ludoApiStatus', ludoApiStatus);
  formData.append("RazorPayout2", RazorPayout2);
    formData.append("RazorDeposit2", RazorDeposit2);
    formData.append("RazorPayKey2", RazorPayKey2);
    formData.append("RazorPaySecretKey2", RazorPaySecretKey2);
    const response = await axios.post(baseUrl + `gatewaysettings`, formData);
    console.log(response.data.status);
    if (response.data.status === "success") {
      alert("Settings submitted successfully");
    } else {
      alert("Settings Not Submitted");
    }
  };
 const handleChangeQR = (val) => {
     console.log(val)
    setgakStatus(val);
    if(val == true){
      axios.post(baseUrl +'api/2fa/setup').then((response) => {
      setQrCode(response.data.qrCodeUrl);
      setSecret(response.data.secret);
    });
    }
  };
  return (
    <>
      <h4 className="text-uppercase font-weight-bold my-3">
       Room Code API
      </h4>
        <div className="form-row">
          <div className="form-group col-md-4">
          <select
              className="form-control "
              name=""
              id=""
              value={ludoApiStatus}
              onChange={(e) => setludoApiStatus(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>
            </div>
            </div>
      <h4 className="text-uppercase font-weight-bold my-3">
        Payment Gateway Settings
      </h4>

      <h5 className="text-uppercase font-weight-bold my-3">RazorPay</h5>

      <form
        action="gatewaysettings"
        className="form"
        onSubmit={handleSubmit1}
        method="patch"
        enctype="multipart/form-date"
      >
        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="buttonrazpay" className="col-6 my-1">
              Razorpay Payout
            </label>
            <select
              className="form-control "
              name=""
              id=""
              value={RazorPayout}
              onChange={(e) => setRazorpayout(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>

            <label htmlFor="buttonrazdep" className="col-6 my-1">
              Razorpay Deposit
            </label>
            <select
              className="form-control "
              name=""
              id=""
              value={RazorDeposit}
              onChange={(e) => setRazorDeposit(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>

            <label htmlFor="buttonrazauto" className="col-6 my-1">
              RazorPay Auto
            </label>
            <select
              className="form-control"
              name=""
              id=""
              value={RazorpayAuto}
              onChange={(e) => setRazorpayAuto(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="buttonrazpay" className="col-6 my-1">
              Razorpay Payout 2
            </label>
            <select
              className="form-control "
              name=""
              id=""
              value={RazorPayout2}
              onChange={(e) => setRazorpayout2(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>

            <label htmlFor="buttonrazdep" className="col-6 my-1">
              Razorpay Deposit 2
            </label>
            <select
              className="form-control "
              name=""
              id=""
              value={RazorDeposit2}
              onChange={(e) => setRazorDeposit2(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>

           
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="RazorpayKey">RazorPay Key</label>
            <input
              className="form-control"
              type="text"
              value={RazorPayKey}
              onChange={(e) => setRazorpayKey(e.target.value)}
            />
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="RazorpaysecretKey">RazorPay SecretKey</label>
            <input
              className="form-control"
              type="text"
              value={RazorPaySecretKey}
              onChange={(e) => setRazorpaysecretKey(e.target.value)}
            />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="RazorpayKey">RazorPay Key 2</label>
            <input
              className="form-control"
              type="text"
              value={RazorPayKey2}
              onChange={(e) => setRazorpayKey2(e.target.value)}
            />
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="RazorpaysecretKey">RazorPay SecretKey 2</label>
            <input
              className="form-control"
              type="text"
              value={RazorPaySecretKey2}
              onChange={(e) => setRazorpaysecretKey2(e.target.value)}
            />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="AccountName">Account Name</label>
            <input
              className="form-control"
              type="text"
              value={AccountName}
              onChange={(e) => setAccountName(e.target.value)}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-4">
            <h5 className="text-uppercase font-weight-bold my-3">PhonePE</h5>

            <label htmlFor="buttondecpay" className="col-2 my-1">
              Status
            </label>
            <select
              className="form-control "
              name=""
              id=""
              value={PhoneStatus}
              onChange={(e) => setPhoneStatus(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>

            <label htmlFor="buttondecdep" className="col-2 my-1">
              MID
            </label>
            <input
              className="form-control"
              type="text"
              value={PhoneMID}
              onChange={(e) => setPhoneMID(e.target.value)}
            />
            <label htmlFor="buttondecdep" className="col-2 my-1">
              KEY
            </label>
            <input
              className="form-control"
              type="text"
              value={PhonePeKey}
              onChange={(e) => setPhonePeKey(e.target.value)}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-4">
            <h5 className="text-uppercase font-weight-bold my-3">
              UPI Gateway
            </h5>

            <label htmlFor="buttondecpay" className="col-2 my-1">
              Status
            </label>
            <select
              className="form-control "
              name=""
              id=""
              value={UpiGateway}
              onChange={(e) => setUpiGateway(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>
            <label htmlFor="buttondecdep" className="col-2 my-1">
              KEY
            </label>
            <input
              className="form-control"
              type="text"
              value={UpiGatewayKey}
              onChange={(e) => setUpiGatewayKey(e.target.value)}
            />
          </div>
        </div>
       <div className="form-row">
          <div className="form-group col-md-4">
            <h5 className="text-uppercase font-weight-bold my-3">
             Manual UPI ID 
            </h5>

           
            <label htmlFor="buttondecdep" className="col-2 my-1">
              upi
            </label>
            <input
              className="form-control"
              type="text"
              value={mannualUpiId}
              onChange={(e) => setMannualUpiId(e.target.value)}
            />
          </div>
                    <div className="form-group col-md-4">
            <h5 className="text-uppercase font-weight-bold my-3">
             Manual Bank Name
            </h5>

           
            <label htmlFor="buttondecdep" className="col-2 my-1">
              Name
            </label>
            <input
              className="form-control"
              type="text"
              value={mannualBankName}
              onChange={(e) => setMannualBankName(e.target.value)}
            />
          </div>
          <div className="form-group col-md-4">
            <h5 className="text-uppercase font-weight-bold my-3">
             Manual Bank IFSC Code
            </h5>

           
            <label htmlFor="buttondecdep" className="col-2 my-1">
              Code
            </label>
            <input
              className="form-control"
              type="text"
              value={mannualIFSC}
              onChange={(e) => setMannualIFSC(e.target.value)}
            />
          </div>
          <div className="form-group col-md-4">
            <h5 className="text-uppercase font-weight-bold my-3">
             Manual Bank Account Number
            </h5>

           
            <label htmlFor="buttondecdep" className="col-2 my-1">
              Account
            </label>
            <input
              className="form-control"
              type="text"
              value={mannualAccount}
              onChange={(e) => setMannualAccount(e.target.value)}
            />
          </div>
          <div className="form-group col-md-4">
            <h5 className="text-uppercase font-weight-bold my-3">
             Manual Qr Code
            </h5>

           
            <label htmlFor="buttondecdep" className="col-2 my-1">
              QR
            </label>
         
          <input className="form-control" type="file" name="mannualQr" onChange={(e)=>setMannualQR(e.target.files[0])}  />
          </div>
             {mannualQR &&<div className="form-group col-md-4">
            <h5 className="text-uppercase font-weight-bold my-3">
              Manual Qr Code
            </h5>

            <img
              src={`https://api.a1gaming.co.in/${mannualQR}`}
              width={100}
              height={100}
            />
          </div>}
        </div>
        <div>
  <h5 className="text-uppercase font-weight-bold my-3">Setup 2FA</h5>
     <div>  <ReactSwitch
                        checked={gakStatus}
                        onChange={handleChangeQR}
                        style={{ display: "none" }}
                      />
                      </div>
      <img src={qrCode} alt="Scan this QR code with Google Authenticator" />
      <p>Save this secret: {secret}</p>
</div>
        <div className="form-row">
          <div className="form-group col-md-4">
            <button type="submit" className="btn btn-dark">
              submit
            </button>
          </div>
        </div>
      </form>
       <div className="form-group col-md-4">
            <h5 className="text-uppercase font-weight-bold my-3">Dummy Game Create</h5>

            <label htmlFor="buttondecpay" className="col-2 my-1">
              Dummy Timeout
            </label>
            <select
              className="form-control "
              name=""
              id=""
              value={dummyTimeout}
              onChange={(e) => setDummyTimeout(e.target.value)}
            >
              <option value="15000" defaultSelected={dummyTimeout == '15000'}>15 Second</option>
              <option value="300000" defaultSelected={dummyTimeout == '300000'}>5 Minutes</option>
            </select>



            <button type="submit" className="btn btn-dark mt-3" onClick={handleDummy}>
              submit
            </button>
          </div>
    </>
  );
};

export default Gateway;
